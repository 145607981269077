import React, { useReducer, useState } from 'react'
// import { FormattedMessage } from "react-intl"
// import { withPrefix } from 'gatsby'
import { Email, Phone, User, Users, Party, Message, Calendar, Location } from '../Icons'
// import axios from 'axios'

import 'flatpickr/dist/themes/dark.css'

import Flatpickr from 'react-flatpickr'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Form(props) {
    // const API_PATH = withPrefix('/api/contact/index.php')
    const defaultDate = new Date()

    const initialState = {
        name: '',
        emailMain: '',
        phone: '',
        email: '',
        date: '',
        partyType: '',
        amount: '',
        address: '',
        message: '',
        mailSent: '',
        error: '',
        sending: false,
    }
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }), { ...initialState })
    const handleDateChange = (date, dateStr) => {
        setUserInput({ date: dateStr })
    }
    const handleChange = (e) => {
        const name = e.target.name
        const newValue = e.target.value
        setUserInput({ [name]: newValue })
    }

    // const handleFormSubmit = (e) => {
    //     e.preventDefault()
    //     setUserInput({ sending: true })
    //     if (!userInput.email) {
    //         axios({
    //             method: 'post',
    //             url: `${API_PATH}`,
    //             headers: { 'content-type': 'application/json' },
    //             data: userInput,
    //         })
    //             .then((result) => {
    //                 setUserInput({ mailSent: result.data.sent })
    //                 setUserInput({ sending: false })
    //                 console.log(userInput)
    //             })
    //             .catch((error) => setUserInput({ error: error.message }))
    //     }
    // }

    const { t } = useTranslation()

    function encode(data) {
        return Object.keys(data)
            .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&')
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setUserInput({ sending: true })
        await fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': event.target.getAttribute('name'),
                ...userInput,
            }),
        })

        setUserInput({ ...initialState, mailSent: true })
    }

    return (
        <section className={`form ${props.showFormState ? 'form--show' : ''}`}>
            <div>
                <button onClick={props.showFormSwitch} className="form__input--closeButton">
                    ×
                </button>
                <h2>{t('form.title')}</h2>

                <form
                    className="form__form d__flex"
                    // onSubmit={(e) => handleFormSubmit(e)}
                    name="contact"
                    onSubmit={handleSubmit}
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    method="POST"
                >
                    <div className="form__input col__md-50">
                        <input
                            type="text"
                            name="name"
                            placeholder={t('form.name')}
                            value={userInput.name}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="name">
                            <User />
                        </label>
                    </div>
                    {/* <div className="form__input d__none">
                        <input
                            type="text"
                            name="email-t"
                            placeholder={t('form.email')}
                            value={userInput.email}
                            onChange={handleChange}
                            required
                        />

                        <label htmlFor="email-t">
                            <Email />
                        </label>
                    </div> */}

                    {/* Boot field below */}
                    <input name="bot-field" hidden />
                    <div className="form__input col__md-50">
                        <input
                            type="text"
                            name="phone"
                            placeholder={t('form.phone')}
                            value={userInput.phone}
                            onChange={handleChange}
                            required
                        />

                        <label htmlFor="phone">
                            <Phone />
                        </label>
                    </div>
                    <div className="form__input">
                        <input
                            type="email"
                            name="email"
                            placeholder={t('form.email')}
                            value={userInput.email}
                            onChange={handleChange}
                            required
                        />

                        <label htmlFor="email">
                            <Email />
                        </label>
                    </div>
                    <div className={`form__input form__input--date ${userInput.date ? 'hasValue' : ''}`}>
                        <Flatpickr
                            options={{
                                minDate: defaultDate,
                                dateFormat: 'j.n.Y',
                            }}
                            name="date"
                            value={userInput.date}
                            onChange={handleDateChange}
                            placeholder={t('form.date')}
                        />

                        <label htmlFor="date">
                            <Calendar />
                        </label>
                    </div>
                    <div className={`form__input form__input--select ${userInput.partyType ? 'hasValue' : ''}`}>
                        <select name="partyType" value={userInput.partyType} onChange={handleChange}>
                            <option value="" disabled hidden>
                                {t('form.type')}
                            </option>
                            <option value="Firemní večírek">{t('form.typeCompany')}</option>
                            <option value="Svadba">{t('form.typeWedding')}</option>
                            <option value="Koncert">{t('form.typeConcert')}</option>
                            <option value="Narozeniny">{t('form.typeBirthday')}</option>
                            <option value="Jiná">{t('form.typeOther')}</option>
                        </select>
                        <label htmlFor="partyType">
                            <Party />
                        </label>
                    </div>
                    <div className={`form__input form__input--select ${userInput.amount ? 'hasValue' : ''}`}>
                        <select name="amount" value={userInput.amount} onChange={handleChange}>
                            <option value="" disabled hidden>
                                {t('form.amount')}
                            </option>
                            <option value="0 - 50">0 - 50</option>
                            <option value="50 - 100">50 - 100</option>
                            <option value="100 - 200">100 - 200</option>
                            <option value="200 - 500">200 - 500</option>
                            <option value="Více než 500">{t('form.amountMore')}</option>
                        </select>
                        <label htmlFor="amount">
                            <Users />
                        </label>
                    </div>
                    <div className="form__input form__input--location">
                        <input
                            type="text"
                            name="address"
                            placeholder={t('form.address')}
                            value={userInput.address}
                            onChange={handleChange}
                            required
                        />

                        <label htmlFor="adress">
                            <Location />
                        </label>
                    </div>
                    <div className="form__input form__input--textarea">
                        <textarea
                            type="text"
                            name="message"
                            rows="5"
                            placeholder={t('form.message')}
                            value={userInput.message}
                            onChange={handleChange}
                            required
                        />

                        <label htmlFor="message">
                            <Message />
                        </label>
                    </div>
                    {userInput.mailSent && (
                        <div className="thankYou">
                            <p>{t('form.thankyou')}</p>
                        </div>
                    )}
                    <div className="form__input--close">
                        <button type="button" onClick={props.showFormSwitch}>
                            {t('form.close')}
                        </button>
                    </div>
                    <div className={`form__input--submit ${userInput.sending ? 'form__input--sending' : ''}`}>
                        <div className="spinner" />
                        <button type="submit">{t('form.submit')}</button>
                    </div>
                </form>
            </div>
        </section>
    )
}
