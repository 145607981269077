import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Star from '../../assets/img/star.svg'
import MessageHex from '../../assets/img/messageHex.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next'
export default function Intro(props) {
    const { t } = useTranslation()
    return (
        <StaticQuery
            query={graphql`
                query {
                    Hero: file(relativePath: { eq: "hero.jpg" }) {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            `}
            render={(data) => {
                return (
                    <section className="intro">
                        <GatsbyImage image={data.Hero.childImageSharp.gatsbyImageData} alt="Hero image" />
                        <div className="intro__text">
                            <h1>{t('intro.title')}</h1>
                            <div className="intro__separator">
                                <span></span>
                                <img src={Star} alt="Star icon" />
                                <span></span>
                            </div>
                            <h2>{t('intro.subtitle')}</h2>
                        </div>
                        <div className="intro__button-container">
                            <button onClick={props.showForm}>
                                <img src={MessageHex} alt="Message icon" />
                            </button>
                        </div>
                    </section>
                )
            }}
        />
    )
}
