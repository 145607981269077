import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import Messages from '../../assets/img/messageBorders.js'

export default function Bio(props) {
    const { t } = useTranslation()
    return (
        <section className="bio" id="about">
            <div className="container">
                <h2>{t('bio.title')}</h2>
                <p dangerouslySetInnerHTML={{ __html: t('bio.content') }} />

                <button onClick={props.showForm}>
                    <Messages firstColor="#00C2D8" secondColor="#00C2D8" />
                </button>
            </div>
        </section>
    )
}
