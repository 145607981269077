import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Gallery() {
    const [index, setIndex] = React.useState('')
    const [selectedImage, setSelectedImage] = React.useState(null)
    const [showLightbox, setShowLightbox] = React.useState(false)

    const imgOnClick = (edge, index) => {
        setShowLightbox(true)
        setSelectedImage(edge)
        setIndex(index)
    }

    const prevImage = (data) => {
        if (index !== 0) {
            setSelectedImage(data.Gallery.edges[index - 1])
            setIndex(index - 1)
        } else {
            setSelectedImage(data.Gallery.edges[data.Gallery.edges.length - 1])
            setIndex(data.Gallery.edges.length - 1)
        }
    }

    const nextImage = (data) => {
        if (index < data.Gallery.edges.length - 1) {
            setSelectedImage(data.Gallery.edges[index + 1])
            setIndex(index + 1)
        } else {
            setSelectedImage(data.Gallery.edges[0])
            setIndex(0)
        }
    }

    const { t } = useTranslation()

    return (
        <StaticQuery
            query={graphql`
                query {
                    Gallery: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
                        edges {
                            node {
                                name
                                relativePath
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => (
                <>
                    <section className="gallery" id="gallery">
                        <div className="container">
                            <h2>{t('gallery')}</h2>

                            <div className="gallery__video">
                                <iframe
                                    key="video1"
                                    title="video1"
                                    width="853"
                                    height="480"
                                    src="https://www.youtube.com/embed/efu0jaqedMY"
                                    frameBorder="0"
                                    allowFullScreen
                                />
                                <iframe
                                    key="video2"
                                    title="video2"
                                    width="853"
                                    height="480"
                                    src="https://www.youtube.com/embed/0rJEPQQad_U"
                                    frameBorder="0"
                                    allowFullScreen
                                />
                            </div>

                            {data.Gallery.edges.map((edge, index) => (
                                <div
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => imgOnClick(edge, index)}
                                    onKeyDown={() => imgOnClick(edge, index)}
                                    key={index}
                                    className="gallery__image"
                                >
                                    <GatsbyImage
                                        image={edge.node.childImageSharp.gatsbyImageData}
                                        alt="Gallery image"
                                    />
                                </div>
                            ))}
                        </div>
                    </section>
                    {showLightbox && (
                        <section className="lightBox">
                            <div>
                                <button onClick={() => setShowLightbox(false)}>×</button>
                                <GatsbyImage image={selectedImage.node.childImageSharp.gatsbyImageData} />

                                <button onClick={() => prevImage(data)}>&#60;</button>
                                <button onClick={() => nextImage(data)}>&#62;</button>
                            </div>
                        </section>
                    )}
                </>
            )}
        />
    )
}
