import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import WhiteStar from '../../assets/img/starWhite.svg'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function Parallax() {
    const { t } = useTranslation()
    return (
        <StaticQuery
            query={graphql`
                query {
                    Parallax: file(relativePath: { eq: "parallax.jpg" }) {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            `}
            render={(data) => (
                <section className="parallax" key={data}>
                    <GatsbyImage image={data.Parallax.childImageSharp.gatsbyImageData} alt="parallax image" />

                    <div className="parallax__text">
                        <h2>{t('parallax.title')}</h2>
                        <p>{t('parallax.text1')}</p>
                        <img src={WhiteStar} alt="star icon" />
                        <p>{t('parallax.text2')}</p>
                    </div>
                </section>
            )}
        />
    )
}
