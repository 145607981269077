import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function About() {
    const { t } = useTranslation()
    return (
        <section className="about">
            <div className="container">
                <h2>Swingalia</h2>
                <p dangerouslySetInnerHTML={{ __html: t('about') }} />
            </div>
        </section>
    )
}
