import React from 'react'
// import { FormattedMessage } from "react-intl"

import Layout from '../components/Layout'
import Seo from '../components/seo'
import Intro from '../components/Intro'
import About from '../components/About'
import Gallery from '../components/Gallery'
import Bio from '../components/Bio'
import Parallax from '../components/Parallax'
import Form from '../components/Form'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
export default function Index() {
    const [showForm, setShowForm] = React.useState(false)

    React.useEffect(() => {
        if (showForm === true) {
            document.body.classList.add('modalOpened')
        } else {
            document.body.classList.remove('modalOpened')
        }
    })
    const { t } = useTranslation()
    return (
        <Layout showForm={() => setShowForm(!showForm)}>
            <Seo title={t('seoTitle')} />
            {/* <FormattedMessage
        id="seoTitle"
        defaultMessage="Swingalia | Energic live band"
      >
        {msg => <SEO title={msg} />}
      </FormattedMessage> */}
            <Intro showForm={() => setShowForm(!showForm)} />
            <About />
            <Gallery />
            <Bio showForm={() => setShowForm(!showForm)} />
            <Parallax />
            <Form showFormState={showForm} showFormSwitch={() => setShowForm(!showForm)} />
        </Layout>
    )
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
